import React from 'react';

import {
  ParticipantSection,
  ParticipantStep,
  ParticipantStepStateTransition,
  ListParticipantStepsResponse,
  Feedback,
  QuizSubmission,
} from '@wix/ambassador-challenges-v1-participant/types';

export type UpdateParticipantStepStatusPayload = {
  sections: ParticipantSection[];
  steps: ParticipantStep[];
  stepId: string;
  transitions?: ParticipantStepStateTransition[];
  feedbackItems?: Feedback;
  quizSubmission?: QuizSubmission;
  replace?: boolean;
};

export interface IParticipantStepsContext {
  selectedStep?: ParticipantStep;
  selectedDate?: Date;
  participantSteps: ListParticipantStepsResponse;
  updateParticipantSteps(
    currentDate?: Date,
    includeAll?: boolean,
  ): Promise<void>;
  isParticipantStepsLoading: boolean;
  updateParticipantStepStatus(
    payload: UpdateParticipantStepStatusPayload,
  ): void;
}

export const ParticipantStepsContext =
  React.createContext<IParticipantStepsContext>({
    selectedStep: undefined,
    participantSteps: null,
    updateParticipantSteps: null,
    isParticipantStepsLoading: false,
    updateParticipantStepStatus: null,
  });
export const useParticipantSteps = () =>
  React.useContext(ParticipantStepsContext);

export const ParticipantStepsConsumer = ParticipantStepsContext.Consumer;
