import { State as ParticipantState } from '@wix/ambassador-challenges-v1-participant/types';
import {
  State as ChallengeState,
  MemberChallenge,
} from '@wix/ambassador-challenges-v1-challenge/types';
import userTypeHandlers from '../../../contexts/User/helpers/userTypeHandlers';
import get from 'lodash/get';
import { getProgramsOrder } from '../getProgramsOrderSettingsKey';

export const filterChallenges = (
  challenges: MemberChallenge[] = [],
  challengesVisible: any,
): MemberChallenge[] => {
  return challenges.filter((challenge) => {
    const participantState = get(
      challenge.summary,
      'participation.state',
    ) as unknown as ParticipantState;
    const isFinished =
      get(challenge, 'challenge.transitions[0].state') ===
      ChallengeState.FINISHED;
    const isParticipant = userTypeHandlers.isJoinedAlready(participantState);

    const currChallengesFromSettings =
      challengesVisible[challenge.challenge.id] ||
      challengesVisible[challenge.challenge.id.substr(0, 5)] ||
      challengesVisible[challenge.challenge.settings.description.title];

    const isChallengeVisible =
      !challengesVisible || typeof currChallengesFromSettings === 'undefined'
        ? true
        : !currChallengesFromSettings?.hidden;

    return (!isFinished || isParticipant) && isChallengeVisible;
  });
};

export const orderChallenges = (
  challenges: MemberChallenge[] = [],
  settings: any,
  chosenCategory: string,
): MemberChallenge[] => {
  const orderFromSettings = getProgramsOrder({
    settings,
    chosenCategory,
  });

  return orderFromSettings.length
    ? [
        ...orderFromSettings
          .map((orderedChall: { id: string }) => {
            const ch = challenges.find((_ch) => {
              return (
                _ch?.challenge?.id.substr(0, 5) ===
                orderedChall?.id?.substr(0, 5)
              );
            });

            return ch || null;
          })
          .filter((item) => !!item),
        ...challenges.filter((ch) => {
          const isInOrder = orderFromSettings.find(
            (i: { id: string }) =>
              i.id.substr(0, 5) === ch?.challenge?.id.substr(0, 5),
          );
          return !isInOrder;
        }),
      ]
    : challenges;
};
