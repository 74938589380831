import React from 'react';

import {
  serverTimelineTypeToClientTimelineString,
  serverToClientPricing,
} from '@wix/challenges-web-library';
import { MemberChallenge } from '@wix/ambassador-challenges-v1-challenge/types';
import userTypeHandlers from '../../../../contexts/User/helpers/userTypeHandlers';
import {
  isFlexible,
  isPrivateChallenge,
  isSpecific,
} from '../../../../selectors/challenges';

import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../settingsParams';

import { Button, ButtonPriority, ButtonSize } from 'wix-ui-tpa/cssVars';
import { ChallengeBadge } from '../../Badge';
import { ChallengeCardSubtitle } from './ChallengeCardSubtitle';

import {
  classes as classesGrid,
  st as stGrid,
} from './ChallengeCardAsGrid.st.css';
import {
  classes as classesSide,
  st as stSide,
} from './ChallengeCardAsSideBySide.st.css';
import { ILayoutType } from '../../Settings/challengeSettings/challengeSettings.types';
import { useLocation } from '../../../../contexts/Location/LocationContext';
import { useChallengesListData } from '../../../../contexts/ChallengesListDataProvider/ChallengesListContext';
import { getMediaPreview } from '../../../../selectors/media';
import { useCSSPBStyle } from '../../../../hooks/useCSSPBStyle';

interface IChallengeCard {
  memberChallenge: MemberChallenge;
  buttonState: 'default' | 'hover';
  userPaidPlans: any;
  goToPage(params: any): void;
  isFullWidth: boolean;
  even?: boolean;
}

export const ChallengeCard: React.FC<IChallengeCard> = (props) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const { baseUrl } = useLocation();
  const { language, isMobile } = useEnvironment();
  const { programPageURI } = useChallengesListData();
  const { experiments } = useExperiments();
  const cssPBEnabled = experiments.enabled('specs.programs.OOIStyleBP');
  const getStyle = useCSSPBStyle();
  const isGridLayout =
    settings.get(settingsParams.layoutType) === ILayoutType.Grid;
  const st = isGridLayout ? stGrid : stSide;

  const classes = isGridLayout ? classesGrid : classesSide;

  const [cardWidth, setCardWidth] = React.useState<number>(0);

  const cardRef = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    setCardWidth(cardRef?.current.offsetWidth || 0);
  }, []);
  React.useEffect(() => {
    if (cardRef?.current?.offsetWidth !== cardWidth) {
      setCardWidth(cardRef?.current.offsetWidth || 0);
    }
  });

  const {
    memberChallenge,
    userPaidPlans,
    goToPage,
    buttonState,
    isFullWidth,
    even,
  } = props;
  const { challenge, summary } = memberChallenge;
  const isDurationMeaningful: boolean =
    isFlexible(memberChallenge?.challenge) ||
    isSpecific(memberChallenge?.challenge) ||
    !!memberChallenge?.challenge?.settings?.timelineType?.selfPaced?.duration;
  const displayParams = cssPBEnabled
    ? {
        image: true,
        duration: isDurationMeaningful,
        participants: true,
        divider: true,
        price: true,
      }
    : {
        image: settings.get(settingsParams.displayImage),
        duration:
          settings.get(settingsParams.displayDuration) && isDurationMeaningful,
        participants: settings.get(settingsParams.displayParticipants),
        divider: settings.get(settingsParams.displayDivider),
        price: settings.get(settingsParams.displayPrice),
      };
  const TitleTag = settings.get(settingsParams.challengeNameTag) || 'h2';

  const participantState = summary?.participation?.state;

  const showParticipantButton = userTypeHandlers.isTouchedChallenge(
    participantState as any,
  );

  const durationString = serverTimelineTypeToClientTimelineString(
    challenge.settings.timelineType as any,
    language,
    t,
    'challenge-card.duration-string.ongoing',
    `challenge-card.duration-string.flexible.days_icu`,
    `challenge-card.duration-string.flexible.weeks_icu`,
    'challenge-card.duration-string.no-limit',
  );

  const pricingString = serverToClientPricing(
    t,
    challenge as any,
    userPaidPlans,
    'challenge-card.pricing.free',
    'challenge-card.pricing.paid.separator',
    'pricing.payment-option.subscription_icu',
    'challenge.page.pricing-options.paid-general',
  );

  const showParticipants = !!(
    displayParams.participants &&
    challenge.participantsSummary.participantsNumber
  );
  const slug = challenge?.settings?.seo?.slug;

  const buttonLabel = settings.get(
    showParticipantButton
      ? settingsParams.buttonTextForParticipant
      : settingsParams.buttonText,
  );

  const programUrl = `${baseUrl || ''}${
    programPageURI || '/challenge-page'
  }/${slug}`;

  const media = challenge?.settings?.description?.media;
  const imgUrl = getMediaPreview(media, 800, 600);

  return (
    <section
      className={st(classes.card, {
        textAlignment: settings.get(settingsParams.textAlignment),
        imageShape: settings.get(settingsParams.imageShape),
        cropSelection: settings.get(settingsParams.cropSelection),
        imageRatio: settings.get(settingsParams.imageRatio),
        imageLayoutType: settings.get(settingsParams.imageLayoutType),
        imageResize: settings.get(settingsParams.imageResizeOptions),
        mobile: isMobile,
        buttonState,
        even,
      })}
    >
      {displayParams.image && (
        <div
          className={classes.media}
          style={getStyle({ displayVar: '--opgDisplayImage' })}
        >
          <a
            tabIndex={-1}
            aria-hidden="true"
            href={programUrl}
            onClick={(e) => {
              e.preventDefault();
              goToPage(e);
            }}
          >
            <div className={classes.ratioBox}>
              {imgUrl ? (
                <img
                  alt=""
                  data-hook="image-wrapper"
                  className={classes.imageWrapper}
                  src={imgUrl}
                />
              ) : null}
            </div>
          </a>
        </div>
      )}
      <div className={classes.info}>
        <ChallengeBadge
          isPrivate={isPrivateChallenge(challenge)}
          summary={summary}
          challengeTransition={
            challenge.transitions['0'] && challenge.transitions['0'].state
          }
        />
        <div
          data-hook="info-wrapper"
          ref={cardRef}
          style={{ position: 'absolute', width: '100%' }}
        />
        <div className={classes.infoWrapper}>
          <a
            href={programUrl}
            onClick={(e) => {
              e.preventDefault();
              goToPage(e);
            }}
          >
            <TitleTag
              className={classes.title}
              data-hook="card-title"
              id={`id-${challenge.id}`}
            >
              {(challenge as any)?.shouldTranslateTitle
                ? t(challenge.settings.description.title)
                : challenge.settings.description.title}
            </TitleTag>
          </a>

          <ChallengeCardSubtitle
            displayParams={displayParams}
            showParticipants={showParticipants}
            durationString={durationString}
            challenge={challenge}
          />
          {displayParams.divider && (
            <div
              data-hook="challenge-divider"
              className={classes.divider}
              style={getStyle({ displayVar: '--opgDisplayDivider' })}
            />
          )}
          {displayParams.price && (
            <p
              data-hook="challenge-pricing"
              className={classes.pricing}
              style={getStyle({ displayVar: '--opgDisplayPrice' })}
            >
              {pricingString}
            </p>
          )}
        </div>
        <div className={classes.buttonWrapper}>
          <Button
            aria-label={`${buttonLabel} ${challenge.settings.description.title}`}
            data-hook="view-button"
            as="a"
            href={programUrl}
            fullWidth={isGridLayout && !(cardWidth > 300 || isFullWidth)}
            className={classes.button}
            priority={ButtonPriority.primary}
            size={ButtonSize.small}
          >
            {buttonLabel}
          </Button>
        </div>
      </div>
    </section>
  );
};

ChallengeCard.displayName = 'ChallengeCard';
