import {
  ISettingsParam,
  createSettingsParams,
  SettingsParamType,
} from '@wix/tpa-settings';

import {
  IChallengeSettings,
  TextAlignment,
  ImageRatio,
  CropSelection,
  VisitorPageLayout,
  ImageAlignment,
  VerticalImageAlignment,
  SidebarPlacement,
  MobileSidebarPlacement,
} from './Settings/challengeSettings/challengeSettings.types';
import { RolesState } from './Widget/settingsEvents';
import { ImageShape } from '../ChallengesList/Settings/challengeSettings/challengeSettings.types';
import { SettingsKeysAbbreviation } from './Settings/constants/settingsKeysAbbreviation';
import challengeSettings from './settingsParams';

export default createSettingsParams<IChallengeSettings>({
  [SettingsKeysAbbreviation.visitorPageBodySectionsOrder]: {
    getDefaultValue() {
      return { order: [] };
    },
  },
  [SettingsKeysAbbreviation.visitorPageSidebarSectionsOrder]: {
    getDefaultValue() {
      return { order: [] };
    },
  },
  [SettingsKeysAbbreviation.visitorPageShareTitle]: {
    getDefaultValue({ t }) {
      return t('live.challenges-page.social-share.title');
    },
  },
  [SettingsKeysAbbreviation.visitorPageToggleShareTitle]: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  [SettingsKeysAbbreviation.visitorPageShareShowInSidebar]: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return false;
    },
  },
  [SettingsKeysAbbreviation.visitorPageGroupDiscussionTitle]: {
    getDefaultValue({ t }) {
      return t('live-site.groups.section-title');
    },
  },
  [SettingsKeysAbbreviation.visitorPageToggleGroupDiscussionTitle]: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  [SettingsKeysAbbreviation.visitorPageGroupDiscussionShowInSidebar]: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return false;
    },
  },
  [SettingsKeysAbbreviation.visitorPageShowGroupImage]: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  [SettingsKeysAbbreviation.visitorPagePriceJoinButtonTitle]: {
    getDefaultValue({ t }) {
      return t('settings.visitor-page.enroll-button');
    },
  },
  [SettingsKeysAbbreviation.visitorPageTogglePriceJoinButtonTitle]: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return false;
    },
  },
  [SettingsKeysAbbreviation.visitorPagePriceTitle]: {
    getDefaultValue({ t }) {
      return t('challenge.page.pricing-options.title-for-free');
    },
  },
  [SettingsKeysAbbreviation.visitorPageTogglePriceTitle]: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  [SettingsKeysAbbreviation.visitorPagePriceShowInSidebar]: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return false;
    },
  },
  [SettingsKeysAbbreviation.visitorPageStartDayJoinButtonTitle]: {
    getDefaultValue({ t }) {
      return t('settings.visitor-page.enroll-button');
    },
  },
  [SettingsKeysAbbreviation.visitorPageToggleStartDayJoinButtonTitle]: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return false;
    },
  },
  [SettingsKeysAbbreviation.visitorPageStartDayTitle]: {
    getDefaultValue({ t }) {
      return t('challenge.page.start-date-selection.title');
    },
  },
  [SettingsKeysAbbreviation.visitorPageToggleStartDayTitle]: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  [SettingsKeysAbbreviation.visitorPageStartDayShowInSidebar]: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  [SettingsKeysAbbreviation.visitorPageInstructorShowInSidebar]: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return false;
    },
  },
  [SettingsKeysAbbreviation.visitorPageInstructorTitle]: {
    getDefaultValue({ t }) {
      return t('settings.visitor-page.instructor-default-title');
    },
  },
  [SettingsKeysAbbreviation.visitorPageToggleInstructorTitle]: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  [SettingsKeysAbbreviation.visitorPageAboutJoinButtonTitle]: {
    getDefaultValue({ t }) {
      return t('settings.visitor-page.enroll-button');
    },
  },
  [SettingsKeysAbbreviation.visitorPageToggleAboutJoinButtonTitle]: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return false;
    },
  },
  [SettingsKeysAbbreviation.visitorPageAboutTitle]: {
    getDefaultValue({ t, getSettingParamValue }) {
      return (
        getSettingParamValue?.(challengeSettings.aboutVstText) ||
        t('settings.visitor-page.sections.about.title')
      );
    },
  },
  [SettingsKeysAbbreviation.visitorPageToggleAboutTitle]: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  [SettingsKeysAbbreviation.visitorPageAboutInstructorImageShape]: {
    getDefaultValue() {
      return ImageShape.Square;
    },
  },
  visitorPageBodySidebarTextAlignment: {
    getDefaultValue() {
      return TextAlignment.Left;
    },
  },
  visitorPageBodySidebarPlacement: {
    getDefaultValue() {
      return SidebarPlacement.right;
    },
  },
  visitorPageBodyMobileSidebarPlacement: {
    getDefaultValue() {
      return MobileSidebarPlacement.bottom;
    },
  },
  visitorPageBodyEnableSidebar: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return false;
    },
  },
  visitorPageImageAlignment: {
    getDefaultValue() {
      return ImageAlignment.left;
    },
  },
  visitorPageVerticalImageAlignment: {
    inheritDesktop: false,
    getDefaultValue({ isMobile }) {
      return isMobile
        ? VerticalImageAlignment.top
        : VerticalImageAlignment.bottom;
    },
  },
  visitorPageLayout: {
    getDefaultValue() {
      return VisitorPageLayout.classic;
    },
    inheritDesktop: false,
  },
  displayRewards: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  displayRewardsForVisitor: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  displayGroup: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  /**
   * sidebar layout
   */
  sidebarLayoutTextAlignment: {
    getDefaultValue() {
      return TextAlignment.Left;
    },
  },
  sidebarLayoutSpace: {
    type: SettingsParamType.Number,
    inheritDesktop: false,
    getDefaultValue() {
      return 0;
    },
  },
  /**
   * participant page sidebar layout
   */
  sidebarLayoutDisplayChallengeDate: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  sidebarLayoutDisplayChallengeDuration: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  sidebarLayoutDisplayChallengeSteps: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  sidebarLayoutDisplayChallengeParticipants: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  /**
   * visitor settings
   */
  editorParticipantRole: {
    inheritDesktop: false,
    getDefaultValue() {
      return RolesState.Visitor;
    },
  },
  headerTextAlignment: {
    getDefaultValue() {
      return TextAlignment.Left;
    },
  },
  contentTextAlignment: {
    getDefaultValue() {
      return TextAlignment.Left;
    },
  },
  imageRatio: {
    getDefaultValue() {
      return ImageRatio.HD;
    },
  },
  cropSelection: {
    getDefaultValue() {
      return CropSelection.Middle;
    },
  },
  displayHeaderDate: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  displayHeaderPrice: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  displayHeaderImage: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  displayChallengeDuration: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  displayChallengeSteps: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  displayChallengeParticipants: {
    type: SettingsParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  displayOwner: {
    getDefaultValue() {
      return true;
    },
  },
  displayOneApp: {
    getDefaultValue() {
      return true;
    },
  },
  displayDivider: {
    getDefaultValue() {
      return true;
    },
  },
  displayTopJoinButton: {
    getDefaultValue() {
      return true;
    },
  },
  displayPriceForFreePrograms: {
    getDefaultValue() {
      return true;
    },
  },
  socialSharingIsEnabled: {
    getDefaultValue() {
      return true;
    },
  },
  socialSharingForParticipantIsEnabled: {
    getDefaultValue() {
      return true;
    },
  },
  textJoinButton: {
    type: SettingsParamType.Text,
    getDefaultValue({ t }) {
      return t('challenge.page.join');
    },
  },

  /**
   * visitor settings: agenda
   */

  agendaIsEnabled: {
    getDefaultValue() {
      return false;
    },
  },
  agendaShowTitle: {
    getDefaultValue() {
      return true;
    },
  },
  agendaTitleText: {
    type: SettingsParamType.Text,
    getDefaultValue({ t }) {
      return t('settings.tab.sections.agenda.section-title-default');
    },
  },
  agendaStepsShown: {
    getDefaultValue() {
      return 5;
    },
  },
  agendaShowMoreText: {
    type: SettingsParamType.Text,
    getDefaultValue({ t }) {
      return t('settings.tab.sections.agenda.load-all-steps-default');
    },
  },
  aboutVstText: {
    type: SettingsParamType.Text,
    getDefaultValue({ t }) {
      return t('challenge.page.content-title');
    },
  },
  completeButtonTxt: {
    type: SettingsParamType.Text,
    getDefaultValue({ t }) {
      return t('challenge.page.steps.complete');
    },
  },
  overviewTabDisplay: {
    getDefaultValue() {
      return true;
    },
  },
});

export const abbreviationToSettingKey = (
  key: SettingsKeysAbbreviation,
): ISettingsParam => {
  return challengeSettings[key];
};
