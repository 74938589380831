import React from 'react';

import { IQuizContext, QuizContext, quizDefaultValue } from './QuizContext';

export const QuizProvider: React.FC<IQuizContext> = (props) => {
  return (
    <QuizContext.Provider
      value={{
        controllerIsReady: !!props.controllerIsReady,
        initController: props.initController || quizDefaultValue.initController,
        sendSubmission: props.sendSubmission || quizDefaultValue.sendSubmission,
        sendSubmissionInProgress: !!props.sendSubmissionInProgress,
        lastSubmissionId:
          props.lastSubmissionId || quizDefaultValue.lastSubmissionId,
        getSubmission: props.getSubmission || quizDefaultValue.getSubmission,
        getSubmissionInProgress: !!props.getSubmissionInProgress,
        submission: props.submission || quizDefaultValue.submission,
        earnedGrade: props.earnedGrade ?? quizDefaultValue.earnedGrade,
        resubmitTheQuiz:
          props.resubmitTheQuiz || quizDefaultValue.resubmitTheQuiz,
        clearQuizStore: props.clearQuizStore || quizDefaultValue.clearQuizStore,
        pagesData: props.pagesData || quizDefaultValue.pagesData,
        setPagesData: props.setPagesData || quizDefaultValue.setPagesData,
      }}
    >
      {props.children}
    </QuizContext.Provider>
  );
};
