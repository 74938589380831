import React from 'react';

import { useSettings } from '@wix/tpa-settings/react';
import { useMemberPaidPlans } from '../../../../contexts/PaidPlans/paidPlansContext';
import { useSettingsEvents } from '../../../../contexts/SettingsEvents/SettingsEvents';
import {
  Pages,
  useLocation,
} from '../../../../contexts/Location/LocationContext';
import { useEnvironment, useBi, useExperiments } from '@wix/yoshi-flow-editor';
import { memberWebAppButtonClick as memberWebAppButtonClickV2 } from '@wix/bi-logger-challenges-member-web/v2';

import settingsParams from '../../settingsParams';
import { ButtonNames } from '../../../../contexts/main/biInterfaces';
import {
  ChallengeSettingsParams,
  ILayoutType,
  ProgramCardsAlignment,
} from '../../Settings/challengeSettings/challengeSettings.types';

import {
  Challenge,
  MemberChallenge,
} from '@wix/ambassador-challenges-v1-challenge/types';

import { ChallengeCard } from '../ChallengeCard';

import { classes, st } from './ChallengesList.st.css';
import { isProgramWidget } from '../../../../selectors/challenges';
import { useIsFullWithInViewer } from '../../../../services/hooks/useIsFullWidthInViewer';

const getGridStyles = (
  isMobile: boolean,
  cssPBEnabled: boolean,
  settings: any,
) => {
  const layoutType = settings.get(settingsParams.layoutType);
  let gridTemplateColumns: string;

  if (isMobile) {
    gridTemplateColumns = `repeat(auto-fit, minmax(max(240px, calc(100% / 2)), 1fr))`;
  } else {
    gridTemplateColumns = `repeat(auto-fit, minmax(max(240px, calc(100% / (${
      cssPBEnabled
        ? 'var(--opgItemsPerRow)'
        : (settings.get(settingsParams.itemsPerRow) as number)
    } + 1))), 1fr))`;
  }

  return layoutType === ILayoutType.Grid
    ? {
        gridTemplateColumns,
        gridGap: cssPBEnabled
          ? 'calc(var(--opgCardSpacing) * 1px)'
          : `${settings.get(settingsParams.cardSpacing)}px`,
        justifyItems:
          settings.get(settingsParams.itemsPerRow) === 1 || isMobile
            ? `center`
            : null,
      }
    : {
        gridTemplateColumns: `1fr`,
        gridGap: cssPBEnabled
          ? 'calc(var(--opgSideCardsVerticalSpacing) * 1px)'
          : `${settings.get(settingsParams.sideCardsVerticalSpacing)}px`,
        justifyItems: `center`,
      };
};

const DIRECTION = {
  [ProgramCardsAlignment.Left]: 'ltr',
  [ProgramCardsAlignment.Right]: 'rtl',
};

export const ChallengesList: React.FC<{
  challenges: MemberChallenge[];
  isEditorLoaded: boolean;
}> = ({ challenges }) => {
  const settings = useSettings();
  const isFullWidth = useIsFullWithInViewer();
  const layoutType = settings.get(settingsParams.layoutType);
  const { isMobile, widgetId } = useEnvironment();
  const { goToPage } = useLocation();
  const { userPaidPlans } = useMemberPaidPlans();
  const { buttonState } = useSettingsEvents();
  const bi = useBi();
  const { experiments } = useExperiments();
  const cssPBEnabled = experiments.enabled('specs.programs.OOIStyleBP');

  const beforeGoToProgramPage = React.useCallback((challenge: Challenge) => {
    if (bi.report) {
      bi.report(
        memberWebAppButtonClickV2({
          buttonName: ButtonNames.ViewChallenge,
          challengeId: challenge?.id,
        }),
      );
    }
  }, []);

  const goToProgramPage = React.useCallback((challenge: Challenge) => {
    goToPage({
      pageId: Pages.Details,
      challengeId: challenge?.settings?.seo?.slug,
      reloadPage: true,
    });
  }, []);

  return (
    <ul
      dir={
        layoutType === ILayoutType.Grid
          ? DIRECTION[
              settings.get(
                settingsParams[ChallengeSettingsParams.ProgramCardsAlignment],
              )
            ]
          : null
      }
      data-hook="challenge-list"
      className={st(classes.root, {
        mobile: isMobile,
        isProgramPage: !isProgramWidget(widgetId),
      })}
      style={getGridStyles(isMobile, cssPBEnabled, settings)}
    >
      {challenges.map((ch, ind) => {
        const challenge: Challenge = ch?.challenge;

        return (
          <li className={classes.item} key={ind}>
            {challenge ? (
              <ChallengeCard
                memberChallenge={ch}
                isFullWidth={isFullWidth}
                goToPage={() => {
                  beforeGoToProgramPage(challenge);
                  goToProgramPage(challenge);
                }}
                userPaidPlans={userPaidPlans}
                buttonState={buttonState}
                even={ind % 2 === 0}
              />
            ) : null}
          </li>
        );
      })}
    </ul>
  );
};
